// import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Navbar, Nav } from "react-bootstrap"
import logo from "../images/puyogg_logo_200px.png"

const Header = props => {
  // console.log(props.location)
  // const [mainRoute, setMainRoute] = useState(props.location)
  const [homeClasses, setHomeClasses] = useState(`nav-link green`)
  const [simulatorClasses, setSimulatorClasses] = useState(`nav-link red`)
  const [drillsClasses, setDrillsClasses] = useState(`nav-link blue`)

  useEffect(() => {
    const mainRoute =
      props.location &&
      props.location.pathname &&
      typeof props.location.pathname === "string"
        ? props.location.pathname.split("/")[1]
        : null
    // console.log(props.location)
    // console.log(props.location.pathname)
    // console.log(mainRoute)
    setHomeClasses(`nav-link green ${mainRoute === "" && "active"}`)
    setSimulatorClasses(`nav-link red ${mainRoute === "simulator" && "active"}`)
    setDrillsClasses(`nav-link blue ${mainRoute === "drills" && "active"}`)
  }, [])

  return (
    <header style={{ background: `#20232a` }}>
      <Navbar expand="lg" className="navbar-dark header">
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="30"
            height="30"
            alt="puyo.gg"
            style={{ marginRight: "0.5em" }}
          />
          <span style={{ position: "relative", top: "-2px" }}>puyo.gg</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Link to="/" className="nav-link green" activeClassName="active">
              Home
            </Link>
            <Link
              to="/simulator/"
              className="nav-link red"
              activeClassName="active"
            >
              Simulator
            </Link>
            <Link
              to="/drills/"
              className="nav-link blue"
              activeClassName="active"
            >
              Drills
            </Link>
            <Link
              to="/slideshowtest/"
              className="nav-link yellow"
              activeClassName="active"
            >
              Slideshow Test
            </Link>
            <a href="http://bit.ly/puyodiscord" className="nav-link purple">
              Community
            </a> */}
            <a href="/" className={homeClasses}>
              Home
            </a>
            <a href="/simulator/" className={simulatorClasses}>
              Simulator
            </a>
            <a href="/drills/" className={drillsClasses}>
              Drills
            </a>
            <a href="/slideshowtest/" className="nav-link yellow">
              Slideshow Test
            </a>
            <a href="http://bit.ly/puyodiscord" className="nav-link purple">
              Community
            </a>
          </Nav>
          {/* <Form
            inline
            style={{
              justifyContent: "center",
              margin: 0,
            }}
          >
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
