import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import PuyoNexusLogo from "../images/puyonexus.png"

const Footer = () => (
  <Container fluid className="px-0">
    <Row noGutters>
      <Col className="footer-col">
        <footer>
          <Container>
            <Row>
              <Col
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `center`,
                }}
              >
                <p>© {new Date().getFullYear()} Puyo Nexus, puyo.gg</p>
                <img
                  src={PuyoNexusLogo}
                  style={{
                    height: `auto`,
                    width: `auto`,
                    maxHeight: `250px`,
                    maxWidth: `400px`,
                  }}
                  alt="Puyo Nexus"
                />
              </Col>
            </Row>
          </Container>
        </footer>
      </Col>
    </Row>
  </Container>
)

export default Footer
