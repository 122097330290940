/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container } from "react-bootstrap"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, pageInfo, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main">
          <Header location={location} />
          {children}
        </Container>
        <Footer />
      </>
    )}
  />
)

export default Layout
